<template>
  <VApp>
    <div class="layout">
      <slot />

      <LazyVDialog
        v-model="showDialogCity"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalCityCheck
          @changed-city="closeMenu('showDialogCity')"
          @close="closeMenu('showDialogCity')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showDialogAddress"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalAddressCheckNew
          v-if="saWStreets && showDialogAddress"
          :redirect="redirect"
          :title="addressCheckTitle"
          :new-window="addressCheckNewWindow"
          @close="closeMenu('showDialogAddress')"
        />
        <LazyModalAddressCheck
          v-else-if="showDialogAddress"
          :redirect="redirect"
          :title="addressCheckTitle"
          :new-window="addressCheckNewWindow"
          @close="closeMenu('showDialogAddress')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showDialogCall"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalCallMePartners
          v-if="provider"
          @close="closeMenu('showDialogCall')"
        />
        <LazyModalCallMe
          v-else
          :btn-title="callMeBtn"
          @close="closeMenu('showDialogCall')"
        />
      </LazyVDialog>
      <LazyVDialog
        v-model="showMap"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalMap
          :redirect="redirect"
          @close="showMap = false"
        />
      </LazyVDialog>
    </div>
  </VApp>
</template>

<script setup lang="ts">
import { VApp } from 'vuetify/components/VApp'
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { debounce } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'
import { providersComposible } from '~/composible/getProviders'

const ctx = useNuxtApp()
const showDialogCity = ref(false)
const showDialogCall = ref(false)
const showMap = ref(false)
const mainStore = useMainStore()
const tariffsStore = useTariffs()
const $gtm = useGtm()
const cityStore = useCities()
const provider = computed(() => mainStore.getDomainConfig)
const $route = useRoute()
const houseUrl = computed(() => cityStore.getHouseUrl)
const houseType = computed(() => cityStore.getHouseType)
const disabledPortal = computed(() => mainStore.disabledPortal)
const metrikaId = computed(() => mainStore.getMetrikaId)
const uaId = computed(() => mainStore.getUaId)
const providerId = computed(() => mainStore.getProviderId)
const currentCity = computed(() => cityStore.getCity)
const saWStreets = computed(() => mainStore.getSaWStreets)
const redirect = ref(false)
const addressCheckTitle = ref('')
const callMeBtn = ref('')
const addressCheckNewWindow = ref(false)
const showDialogAddress = ref(false)

const closeMenu = (val) => {
  if (val === 'showDialogCity') showDialogCity.value = false
  else if (val === 'showDialogAddress') showDialogAddress.value = false
  else if (val === 'showDialogCall') showDialogCall.value = false
}

const providers = ref()

const { fetchProvidersList } = providersComposible()

const sendGtm = debounce(() => {
  if ($gtm) {
    $gtm.trackEvent({
      event: 'addressCheck',
      providers: providers.value.map((el) => {
        return {
          slug: el.provider.slug,
          id: el.provider.id,
          name: el.provider.name,
        }
      }),
      houseType: houseType.value,
      providersAlt: providers.value
        .map(el => el.provider.slug)
        .join(' '),
      providersCount: providers.value.length,
    })
  }
}, 300)

const sendGtmCall = debounce((action, label?) => {
  if ($gtm) {
    $gtm.trackEvent({
      event: 'trackEvent',
      category: 'call',
      action,
      label,
    })
  }
}, 300)

const sendGtmEvent = debounce(
  (event: any, category: any, action: any, label?: any) => {
    if ($gtm) {
      $gtm.trackEvent({
        event,
        category,
        action,
        label,
      })
    }
  },
  300,
)

const loadListen = () => {
  ctx.$listen(
    'cityDialog',
    (
      val:
        | any
        | { redirect: boolean, label: string },
    ) => {
      sendGtmEvent('trackEvent', 'address', 'map-open', val.label)
      showDialogCity.value = val.redirect
    })
  ctx.$listen(
    'addressDialog',
    (
      val:
        | any
        | { redirect: boolean, label: string, title: string, newWindow: boolean },
    ) => {
      redirect.value = val.redirect
      addressCheckTitle.value = val.title || ''
      addressCheckNewWindow.value = !!val.newWindow
      sendGtmEvent('trackEvent', 'address', 'open', val.label)
      showDialogAddress.value = true
    },
  )
  ctx.$listen('callDialog', (val: string) => {
    callMeBtn.value = val
    showDialogCall.value = true
  })
  ctx.$listen('mapDialog', (val: { label: string, redirect: boolean }) => {
    sendGtmEvent('trackEvent', 'address', 'map-open', val.label)
    redirect.value = val.redirect
    showMap.value = true
  })
  ctx.$listen('gtmSendCall', (val) => {
    sendGtmCall(val.action, val.label)
  })

  ctx.$listen('gtmSendEvent', (val: any) => {
    sendGtmEvent(val.event, val.category, val.action, val.label)
  })
}

watch(
  () => houseUrl.value,
  async () => {
    if (houseUrl.value) {
      providers.value = await fetchProvidersList()
      sendGtm()
    }
  },
)

const changeMenu = () => {
  mainStore.$patch({
    disabledPortal: window.innerWidth > 1000,
    disabledMobile: window.innerWidth > 767,
  })
}

onBeforeMount(async () => {
  nextTick(() => {
    let recentSearch = localStorage.getItem('recentSearch')
    if (recentSearch) {
      recentSearch = JSON.parse(recentSearch)?.filter(el => el.selecetedHouse)
      tariffsStore.$patch({
        recentSearch: recentSearch,
      })
    }
  })
  if ($route.fullPath.toString().includes('utm')) {
    const queries = new URLSearchParams($route.fullPath.split('?')[1])
    const utmArr = [] as any[]
    for (const query of queries.entries()) {
      if (query[0].includes('utm')) {
        utmArr.push(query)
      }
    }
    if (utmArr.length) {
      mainStore.$patch({
        utmArr,
      })
    }
  }
  changeMenu()
})

onMounted(() => {
  loadListen()
  if (window) {
    changeMenu()
    window.addEventListener('resize', changeMenu)
  }
  const compare = useCookie('compare')
  if (compare.value)
    tariffsStore.$patch({
      compareList: compare.value,
    })
  if ('serviceWorker' in navigator) {
    // Отменяем регистрацию текущего Service Worker и удаляем его
    navigator?.serviceWorker?.getRegistrations()?.then((registrations) => {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
})

onBeforeUnmount(() => {
  ctx.$unlisten('gtmSendEvent')
  ctx.$unlisten('cityDialog')
  ctx.$unlisten('addressDialog')
  ctx.$unlisten('callDialog')
  ctx.$unlisten('mapDialog')
  ctx.$unlisten('gtmSendCall')
  if (window) {
    window.removeEventListener('resize', changeMenu)
  }
})

useHead(() => ({
  title: '',
  link: [
    {
      rel: 'canonical',
      href: () =>
        provider.value
          ? `https://${provider.value?.domain}${$route.path}`
          : 'https://dominternet.ru' + $route.path,
    },
    // {rel: 'manifest', href: `/manifest.json` },
    {
      hid: 'apple-touch-icon',
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-icon-180x180.png',
    },
    {
      rel: 'mask-icon',
      hid: 'mask-icon',
      purpose: 'maskable any',
      href: '/safari-pinned-tab.svg',
      color: '#5bbad5',
    },
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
  ],
  meta: [
    { hid: 'og:type', property: 'og:type', content: 'product' },
    { hid: 'og:locale', property: 'og:locale', content: 'ru_RU' },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () =>
        provider.value ? `${provider.value?.domain}` : 'dominternet.ru',
    },
    { hid: 'og:url', property: 'og:url', content: $route.path },
  ],
  script: [
    {
      hid: 'gtm',
      children: `(function (w, d, l) {
        w[l] = w[l] || [];
        const cookie = d.cookie.split('; ')
        const fias_id = '${currentCity.value?.fias_id || ``}'
        const page_version = d.cookie.includes('ab_test') ? d.cookie.split('; ').find(el => el.includes('ab_test')).replace('ab_test=', '') : ''
        const metrika_id = '${metrikaId.value || ``}'
        const ua_id = '${uaId.value || ``}'
        const provider_id = '${providerId.value || ``}'
        w[l].push({
          originalLocation: d.location.protocol + '//' +
            d.location.hostname +
            d.location.pathname +
            d.location.search,
          fias_id,
          page_version,
/*          metrika_id,*/
          ua_id,
          provider_id,
        });
      })(window, document, 'dataLayer')`,
      type: 'text/javascript',
    },
    {
      children: () => `{
          "@context": "http://schema.org",
          "@type": "Organization",
          "url": "${
  provider.value
    ? `https://${provider.value?.domain}${$route.path}`
    : 'https://dominternet.ru' + $route.path
  }",
          "logo": "${
  provider.value
    ? `https://${provider.value?.domain}/android-icon-192x192.png`
    : 'https://dominternet.ru/android-icon-192x192.png'
  }"
          }`,
      type: () => 'application/ld+json',
    },
  ],
}))
</script>

<style lang="scss">
.layout,
.v-overlay {
  font-family: Rubik, Roboto, Arial, sans-serif;
}
</style>
